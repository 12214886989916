define("discourse/plugins/discourse-subscription-client/discourse/templates/connectors/admin-wizards-top/deprecation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-client-sub-deprecation-notice">
    <h3>👋 Hey there Custom Wizard Subscriber</h3>
    <p>Pavilion is happy to announce that Custom Wizard Subscriptions no longer require the <a href="https://github.com/paviliondev/discourse-subscription-client" target="_blank">Subscription Client Plugin</a>. Please remove the Subscription Client Plugin, and your subscription will continue to work as normal. If you have any questions don't hestiate to reach out to us on <a href="https://coop.pavilion.tech">coop.pavilion.tech</a> or email <a href="mailto:support@pavilion.tech">support@pavilion.tech</a>. This message will disappear when you remove the Subscription Client Plugin.</p>
  </div>
  
  */
  {
    "id": "3Hp1wZM3",
    "block": "[[[10,0],[14,0,\"admin-client-sub-deprecation-notice\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"👋 Hey there Custom Wizard Subscriber\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Pavilion is happy to announce that Custom Wizard Subscriptions no longer require the \"],[10,3],[14,6,\"https://github.com/paviliondev/discourse-subscription-client\"],[14,\"target\",\"_blank\"],[12],[1,\"Subscription Client Plugin\"],[13],[1,\". Please remove the Subscription Client Plugin, and your subscription will continue to work as normal. If you have any questions don't hestiate to reach out to us on \"],[10,3],[14,6,\"https://coop.pavilion.tech\"],[12],[1,\"coop.pavilion.tech\"],[13],[1,\" or email \"],[10,3],[14,6,\"mailto:support@pavilion.tech\"],[12],[1,\"support@pavilion.tech\"],[13],[1,\". This message will disappear when you remove the Subscription Client Plugin.\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-subscription-client/discourse/templates/connectors/admin-wizards-top/deprecation.hbs",
    "isStrictMode": false
  });
});